import styles from './Empty.module.css';

export function Empty(){

    return(
        <div className={styles.empty}>
            No Results
        </div>

    )

}